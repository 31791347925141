import { Category, PlanDetails, PlanType, Tariffs } from "@/types/data";
import Link from "next/link";
import React from "react";

export enum SiteName {
	Solstice = "Solstice",
	SolsticeVictoria = "Solstice Victoria",
}

export const siteName = process.env.NEXT_PUBLIC_SITE as SiteName;
export const isTasmania = siteName === SiteName.Solstice;
export const isVictoria = siteName === SiteName.SolsticeVictoria;

export const categories: { [key in SiteName]: Category[] } = {
	[SiteName.Solstice]: [
		{ title: "Different Energy", value: "different-energy" },
		{ title: "Residential", value: "moving" },
		{ title: "Business", value: "business" },
		{ title: "Help & Support", value: "help-and-support" },
		{ title: "About us", value: "about-us" },
	],
	[SiteName.SolsticeVictoria]: [
		{ title: "Different Energy", value: "different-energy" },
		{ title: "Residential", value: "moving" },
		{ title: "Business", value: "business" },
		{ title: "Help & Support", value: "help-and-support" },
		{ title: "About us", value: "about-us" },
	],
};

export const Tags = {
	page: "page",
	faqItem: "faq",
	faqCategory: "faq-category",
	home: "home",
	form: "form",
	product: "product",
	productGas: "gasProduct",
	pipelineMap: "pipeline",
	404: "404",
	emergencyBanner: "emergency-banner",
};

export const ALL_CATEGORY = "all";

export const SEND_PROPERTIES = {
	SEND_FROM: "101",
	SEND_TO: "102",
	SEND_TIME: "103",
	SEND_SUBJECT: "104",
};

export const acceptedFiles = [
	"application/pdf",
	"image/png",
	"image/jpeg",
	"image/jpg",
	"image/heic",
];

interface ErrorMessage {
	title: string;
	body: React.ReactNode;
	link?: {
		title: string;
		link: string;
	};
}

export const errorMessages: Record<string, ErrorMessage> = {
	promo: {
		title: `Looks like that promo code isn’t right.`,
		body: "Try again or if it keeps happening, ",
		link: { title: "contact us", link: "/contact-us" },
	},
	generic: {
		title: "Looks like something went wrong.",
		body: "If it keeps happening, ",
		link: { title: "contact us", link: "/contact-us" },
	},
	noPlan: {
		title: `Oops! Something’s not quite right.`,
		body: (
			<>
				<p>Please try again in a few minutes.</p>
				<p>If it's still not working our customer service team can help you.</p>
				<p>
					Give us a call on 1800 750 750 or{" "}
					<Link
						href="/contact-us"
						target="_blank"
					>
						contact us here
					</Link>{" "}
					and we’ll get in touch.
				</p>
				<p>
					If you’re transferring from another retailer, have your last bill handy to speed up the
					process.
				</p>
			</>
		),
	},
};

export const siteURL =
	process.env.NEXT_PUBLIC_SITE_URL || "https://" + process.env.NEXT_PUBLIC_VERCEL_URL;
export const siteAPIURL = siteURL + "/api";
